import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { isArray } from "react-advanced-cropper";

const DownloadContract = ({ campaign, contract }) => {
  const [interestedCampaignContract, setInterestedCampaignContract] = useState(null)

  const fetchFileContent = async (url) => {
    const response = await fetch(url);
    const content = await response.text();
    return content;
  };

  const downloadAsPDF = async (content, filename) => {
    const element = document.createElement('div');
    element.innerHTML = content;
    document.body.appendChild(element);

    const canvas = await html2canvas(element, {
      scale: 2,
    });

    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgData = canvas.toDataURL('image/png');
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    pdf.save(filename);

    document.body.removeChild(element);
  };

  function getInfluencerContract(contracts) {
    let contract = null;

    if (contracts && isArray(contracts) && contracts.length) {
      contracts.some((item) => {
        if (item.userId.toString() === localStorage.getItem("currentUser").toString()) {
          contract = item.customContract;
          return true;
        }
        return false;
      });
    }

    return contract;
  }

  const signatures = (contract) => {
    return `
      <div
        style="display: flex; justify-content: space-around; align-items: flex-start; margin: 20px;"
      >
        <!-- Brand Signature -->
        <div style="text-align: center; width: 30%;">
          <h2>Brand Signature</h2>
          <div
            style="height: 150px; display: flex; justify-content: center; align-items: center;"
          >
            ${contract.brandSignature ?
        `<img
                src="${contract.brandSignature}"
                alt="Brand Signature"
                style="max-width: 150px; max-height: 150px; margin-bottom: 10px;"
              />`
        : "<label>No signature</label>"
      }
          </div>
        </div>
  
        <!-- Influencer Signature -->
        <div style="text-align: center; width: 30%;">
          <h2>Influencer Signature</h2>
          <div
            style="height: 150px; display: flex; justify-content: center; align-items: center;"
          >
            ${contract.influencerSignature ?
        `<img
                src="${contract.influencerSignature}"
                alt="Influencer Signature"
                style="max-width: 150px; max-height: 150px; margin-bottom: 10px;"
              />`
        : "<label>No signature</label>"
      }
          </div>
        </div>
  
        <!-- Admin Signature -->
        <div style="text-align: center; width: 30%;">
          <h2>Admin Signature</h2>
          <div
            style="height: 150px; display: flex; justify-content: center; align-items: center;"
          >
            ${contract.adminSignature ?
        `<img
                src="${contract.adminSignature}"
                alt="Admin Signature"
                style="max-width: 150px; max-height: 150px; margin-bottom: 10px;"
              />`
        : "<label>No signature</label>"
      }
          </div>
        </div>
      </div>
    `;
  };


  const handleDownload = async () => {
    let combinedContent = await fetchFileContent("https://authentic.lon1.cdn.digitaloceanspaces.com/authentic_default_contract.txt");

    if (campaign.contract) {
      const content = await fetchFileContent(campaign.contract);
      combinedContent += `<h1>Brand Additional Clause</h1><div>${content}</div><br/>`
    }

    if (contract.customContract) {
      const influ_contract = getInfluencerContract(contract.customContract)
      if (influ_contract) {
        const content = await fetchFileContent(influ_contract);
        combinedContent += `<h1>Influencer Additional Clause</h1><div>${content}</div><br/>`
      }
    }

    const signaturesHtml = interestedCampaignContract ? `<div>${signatures(interestedCampaignContract)}</div>` : '';
    combinedContent += `<br/><h1>Signatures</h1><div>${signaturesHtml}</div>`;

    downloadAsPDF(combinedContent, 'download_contract.pdf');
  }


  useEffect(() => {
    if (campaign.campaignContracts) {
      const interested = campaign.campaignContracts.filter(contract => contract.contractType === 'Interested');
      interested.length && setInterestedCampaignContract(interested[0])
    }
  }, [campaign.campaignContracts])


  return (
    <Button variant="contained" style={{ marginRight: "15px" }} onClick={handleDownload}>
      Download Contract
    </Button>
  );
};

export default DownloadContract;
