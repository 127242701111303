import { Typography } from "@mui/material";

const Signatures = ({ contract }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      alignItems: "flex-start",
      margin: '20px'
    }}
  >
    {/* Brand Signature */}
    <div style={{ textAlign: "center", width: "30%" }}>
      <Typography variant="subtitle2">
        Brand Signature
      </Typography>
      <div
        style={{
          height: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {contract.brandSignature ? (
          <>
            <img
              src={contract.brandSignature}
              alt="Brand Signature"
              style={{
                maxWidth: 150,
                maxHeight: 150,
                marginBottom: 10,
              }}
            />
          </>
        ) : <label>No signature</label>}
      </div>
    </div>

    {/* Influencer Signature */}
    <div style={{ textAlign: "center", width: "30%" }}>
      <Typography variant="subtitle2">
        Influencer Signature
      </Typography>
      <div
        style={{
          height: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {contract.influencerSignature ? (
          <>
            <img
              src={contract.influencerSignature}
              alt="Influencer Signature"
              style={{
                maxWidth: 150,
                maxHeight: 150,
                marginBottom: 10,
              }}
            />
          </>
        ) : (
          <label>No signature</label>
        )}
      </div>
    </div>

    {/* Admin Signature */}
    <div style={{ textAlign: "center", width: "30%" }}>
      <Typography variant="subtitle2">
        Admin Signature
      </Typography>
      <div
        style={{
          height: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {contract.adminSignature ? (
          <>
            <img
              src={contract.adminSignature}
              alt="Admin Signature"
              style={{
                maxWidth: 150,
                maxHeight: 150,
                marginBottom: 10,
              }}
            />
          </>
        ) : (
          <label>No signature</label>
        )}
      </div>
    </div>
  </div>
)

export default Signatures;