import { gql } from '@apollo/client';


const GET_CAMPAIGN_DETAILS = gql`
  query getCampaignDetails($campaignId: ID!) {
    getCampaignDetails(campaignId: $campaignId) {
      id
      ageGroup
      followerPreference
      campaignType
      category{
        id
        name
      }
      campaignContracts {
        id
        contractType
        brandName
        adminName
        influencerName
        brandSignature
        adminSignature
        influencerSignature
        brandDate
        adminDate
        influencerDate
      }
      coverPic
      description
      femalePercentage
      malePercentage
      media
      name
      status
      startDate
      endDate
      contract
      user{
        agencyCommission
      }
    }
  }`

  export default GET_CAMPAIGN_DETAILS;