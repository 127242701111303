
const integrationRedirection = ({ userName, integrationType }) => {
  let username = userName

  if (integrationType === 'instagram') {
    username = username?.startsWith('@') ? username.slice(1) : username;
  } else {
    username = username?.startsWith('@') ? username : "@" + username
  }

  if (/Mobi|Android/i.test(navigator.userAgent)) {
    window.location.href = `${integrationType}://user?username=${username}`;
  } else {
    // Open Instagram web profile on desktop (and mobile devices without the app)
    window.open(`https://www.${integrationType}.com/${username}`, "_blank");
  }

}

export default integrationRedirection