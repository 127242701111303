import {
  Grid,
  Typography,
  TableContainer,
  Paper,
  Stack,
  AvatarGroup,
  Avatar,
  Modal,
  Box,
} from "@mui/material";
import { Fragment, useState } from 'react';
import { styled } from "@mui/material/styles";
import GET_SURVEY_INFLUENCERS from "../../graphql/queries/getSurveyInfluencers";
import { useLazyQuery, useQuery } from "@apollo/client";
import MiniInfluencerCard from "./miniInfluencerCard";

import DOG from "../../assets/dog.jpeg";
import BEACH from "../../assets/beach.jpeg";
import BATHTUB from "../../assets/bathtub.jpeg";
import PLATE from "../../assets/plate.jpeg";
import CampaignBanner from "../../assets/clouds.jpeg"

const style = {
  position: "absolute",
  alignItems: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  overflowY: "auto",
  borderRadius: '15px',
  backgroundImage: `url(${CampaignBanner})`,
};



const SurveyPlaceholder = (props) => {
  const dummy_response = { getMySurveys: [{ id: 1, question: "Do you own a pet Chihuahua?", answers: ['Yes', 'No'], coverImage: DOG }, { id: 2, question: "Are you heading abroad this year to a beach destination?", answers: ['Yes', 'No', 'Maybe', 'N/A'], coverImage: BEACH }, { id: 3, question: "Calling all vegan Influencers for a food event in June, register your interest now.", answers: ['Interested', 'Not Interested'], coverImage: PLATE }, { id: 4, question: "Register your interest for the Silento fashion event in September.", answers: ["I'm interested", " Not interested", "N/A"], coverImage: BATHTUB }] }
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));


  const validateData = () => {
    return props.data !== undefined && props.data !== null && props.data.getMySurveys?.length > 0
  }

  const data = validateData() ? props.data : dummy_response;

  const handleInfluencerClick = (answer, surveyId) => {
    fetchInfluencers({
      variables: {
        surveyId: surveyId,
        answer: answer,
      },
    });
  };

  const [influencersPopup, showInfluencersPopup] = useState(false);
  const [fetchInfluencers, { data: influencers }] = useLazyQuery(
    GET_SURVEY_INFLUENCERS,
    {
      onCompleted: () => {
        showInfluencersPopup(true);
      },
    }
  );

  return (
    <Fragment>
      {!validateData() && (
        <Typography
          variant="h6"
          sx={{ width: "100%", marginBottom: 3 }}
          align="center"
        >
          If you want to create surveys to find creators for your event and more, contact admin to create surveys like the ones below.
        </Typography>
      )}
      <TableContainer component={Paper} sx={{ maxWidth: '100%', display: 'flex' }}>
        <Grid container spacing={3} justifyContent="flex-start">
          {data?.getMySurveys?.map((survey, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}

                key={`survey-#-${survey.id}`}
                container
                justifyContent="center"
                p={3}
              >
                <img
                  src={survey.coverImage}
                  alt={survey.question}
                  width="400"
                  height="400"
                  style={{
                    borderRadius: "15px",
                    objectFit: "contain",
                    width: "100%",
                    maxWidth: "400px",
                    height: "auto",
                  }}
                />
                <Grid item xs={12}>
                  <Typography variant="h6" mt={2} mb={2} align="center" minHeight="80px">
                    {survey.question}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="start"
                    justifyContent="center"
                    spacing={{
                      xs: 0.5,
                      sm: 1,
                    }}
                  >

                    {survey.answers.filter(answer => answer.trim() !== '').map((answer, index) => (
                      <Stack
                        key={survey.id + index}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={{
                          xs: 0.1,
                          sm: 0.5,
                        }}
                        sx={{ width: 120 }}
                      >
                        <Item
                          sx={{
                            width: 126,
                            backgroundColor: "#FFA3C7",
                            color: "#FFF",
                          }}
                        >
                          {answer}
                        </Item>
                        <Stack
                          key={survey.id + "avatars" + index}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          spacing={{
                            xs: 0.1,
                            sm: 0.1,
                          }}
                          sx={{ width: 200, marginTop: 1 }}
                        >
                          <AvatarGroup
                            max={2}
                            onClick={() =>
                              handleInfluencerClick(answer, survey.id)
                            }
                          >
                            {survey?.surveyAnswers?.map(
                              (response, index) =>
                                response.answer === answer && (
                                  <Avatar
                                    alt={response.user.name}
                                    src={response.user.image}
                                    sx={{
                                      "&:hover": {
                                        border: "3px solid #EF2E83",
                                      },
                                    }}
                                  />
                                )
                            )}
                          </AvatarGroup>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </TableContainer>

      <Modal
        open={influencersPopup}
        onClose={() => showInfluencersPopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container sx={{ margin: "0", padding: "0 10px", width: "100%", gap: "10px" }} spacing={3}>
            {influencers?.brandGetSurveyInfluencers.map(
              (influencer) =>
                influencer.userGalleries.length > 0 && (
                  <MiniInfluencerCard
                    city={influencer.city || ""}
                    country={influencer.country || ""}
                    id={influencer.id || ""}
                    image={influencer.image || ""}
                    instagram={influencer.instagram || ""}
                    instagramStat={influencer.instagramStat || ""}
                    name={influencer.name || ""}
                    tiktok={influencer.tiktok || ""}
                    tiktokStat={influencer.tiktokStat || ""}
                    userGalleries={influencer.userGalleries || ""}
                  />
                )
            )}
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
}
export default SurveyPlaceholder;
